<template>
  <div class='page-box'>
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-row>
        <a-form-model-item label="账户名称" prop="realName">
          <a-input v-model="queryRef.realName" placeholder="请输入" />
        </a-form-model-item>

        <a-form-model-item label="联系方式" prop="phone">
          <a-input v-model="queryRef.phone" placeholder="请输入" />
        </a-form-model-item>

        <a-form-model-item label="审核状态" prop="state">
          <a-select v-model="queryRef.state" placeholder='请选择' style="width: 120px">
            <a-select-option v-for='item in withdrawState' :key='item.value' :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="提现类型" prop="withdrawType">
          <a-select v-model="queryRef.withdrawType" placeholder='请选择' style="width: 120px">
            <a-select-option v-for='item in withdrawType' :key='item.value' :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="账户类型" prop="cardType">
          <a-select v-model="queryRef.cardType" placeholder='请选择' style="width: 120px">
            <a-select-option v-for='item in cardType' :key='item.value' :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        </a-row>
        <a-row>
        <a-form-model-item label="添加时间" prop="dateRange">
          <a-range-picker v-model="dateRange" />
        </a-form-model-item>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(0)">
          今
        </a-button>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(1)">
          昨
        </a-button>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(7)">
          近7天
        </a-button>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(30)">
          近30天
        </a-button>
        <a-form-model-item class="screen-btns">
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
        </a-row>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button type="primary" @click="exportFn" :loading="exporting"> 导出 </a-button>
    </div>
    <base-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.userSharerId" :loading="loadingRef" @change="handleTableChange">
      <template #state="record">
        <span :style="{color: withdrawState.find(x=>x.value === record).color}">{{withdrawState.find(x=>x.value === record).name}}</span>
      </template>
      <template #withdrawType="record">
        <span :style="{color: withdrawType.find(x=>x.value === record).color}">{{withdrawType.find(x=>x.value === record).name}}</span>
      </template>
      <template #withdrawMode="record">
        <span :style="{color: withdrawMode.find(x=>x.value === record).color}">{{withdrawMode.find(x=>x.value === record).name}}</span>
      </template>
      <template #cardType="record">
        <span :style="{color: cardType.find(x=>x.value === record).color}">{{cardType.find(x=>x.value === record).name}}</span>
      </template>
      <template #operation="_,record">
        <div class='table-operations '>
          <a-button type='link' @click='passWithdraw(record)' v-if='record.state === 0 '>通过</a-button>
          <a-button type='link' @click='refuseWithdraw(record)' v-if='record.state === 0'>拒绝</a-button>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import { marketing } from '@/api'
import { withdrawState, withdrawType, withdrawMode, cardType } from '../../utils/constants'
const columns = [
  {
    title: '账户名/姓名',
    dataIndex: 'cardRealName',
  },
  {
    title: '联系方式',
    dataIndex: 'phone',
  },
  {
    title: '会员职级',
    dataIndex: 'levelName',
  },
  {
    title: '所属上级',
    dataIndex: 'parentName',
  },
  {
    title: '提现方式',
    dataIndex: 'withdrawMode',
    scopedSlots: { customRender: 'withdrawMode' },
  },
  {
    title: '提现类型',
    dataIndex: 'withdrawType',
    scopedSlots: { customRender: 'withdrawType' },
  },
  {
    title: '账户类型',
    dataIndex: 'cardType',
    scopedSlots: { customRender: 'cardType' },
  },
  {
    title: '提现金额',
    dataIndex: 'withdrawAmount',
  },
  {
    title: '手续费',
    dataIndex: 'handingFee',
  },
  {
    title: '到账金额',
    dataIndex: 'factIntoAmount',
  },
  {
    title: '剩余可提现金额',
    dataIndex: 'amount',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '申请时间',
    dataIndex: 'createTime',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

export function downloadFile (fetcher, defaultFileName = '下载_' + Date.now()) {
  return fetcher().then((res) => {
    console.log(res)
    const { headers, data: blob } = res
    const m = /filename=("|')?(.+)\1($|\s|;)/.exec(headers['content-disposition'])
    const serverSuggestName = m && m[2] ? decodeURIComponent(m[2]) : defaultFileName

    // IE & 旧版本Edge
    if ('msSaveOrOpenBlob' in navigator) {
      navigator.msSaveOrOpenBlob(blob, serverSuggestName)
    } else {
      const el = document.createElement('a')
      const url = URL.createObjectURL(blob)
      el.setAttribute('href', url)
      el.setAttribute('download', serverSuggestName)
      el.style.display = 'none'
      document.body.append(el)
      el.click()
      setTimeout(() => {
        URL.revokeObjectURL(url)
        el.remove()
      })
    }
  })
}

export function useExport (fetcher, defaultFileName) {
  const exporting = ref(false)
  async function exportFn () {
    exporting.value = true
    await downloadFile(fetcher, defaultFileName)
    exporting.value = false
  }
  return {
    exporting,
    exportFn
  }
}
export default {
  setup (props, { root }) {
    const formRef = ref(null)
    const dateRange = ref([])
    watch(
      () => dateRange.value,
      (data) => {
        if (data.length) {
          queryRef.value.startTime = `${moment(dateRange.value[0]).format('YYYY-MM-DD')} 00:00:00`
          queryRef.value.endTime = `${moment(dateRange.value[1]).format('YYYY-MM-DD')} 23:59:59`
        } else {
          queryRef.value.startTime = ''
          queryRef.value.endTime = ''
        }
      }
    )

    const queryRef = ref({
      realName: '',
      phone: '',
      orderState: '',
      startTime: '',
      endTime: '',
      state: undefined,
    })
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const levels = ref([])
    const loadingRef = ref(false)
    onMounted(() => {
      Promise.all([getLevelData(), getTableData()])
    })
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } = await marketing.getWithdrawList({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false

      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      dateRange.value = []
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      pageRtv.value.current = current
      getTableData()
    }

    function handleDateShortcut (day) {
      dateRange.value = [
        moment().subtract(day, 'd'),
        day === 1 ? moment().subtract(1, 'd') : moment(),
      ]
    }
    async function getLevelData () {
      const { data, msg, code } = await marketing.getLevelData()
      if (code === '00000') {
        levels.value = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function passWithdraw (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认通过此条提现申请？',
        onOk: async () => {
          const { code, msg } = await marketing.passWithdraw({
            sharerWithdrawApplyId: record.sharerWithdrawApplyId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function refuseWithdraw (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认拒绝此条提现申请？',
        onOk: async () => {
          const { code, msg } = await marketing.refuseWithdraw({
            sharerWithdrawApplyId: record.sharerWithdrawApplyId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }

    const { exportFn, exporting } = useExport(() => marketing.exportWithdrawInfo(queryRef.value))

    return {
      queryRef,
      formRef,
      dateRange,
      handleDateShortcut,
      handleTableChange,
      handleReset,
      handleSearch,
      columns,
      levels,
      withdrawState,
      withdrawType,
      withdrawMode,
      cardType,
      loadingRef,
      dataRef,
      pageRtv,
      passWithdraw,
      refuseWithdraw,
      exportFn,
      exporting
    }
  },
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
.record-list {
  margin-bottom: 32px;
  .record-item {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
